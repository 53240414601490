import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS } from '../../../constants'

export const styles = makeStyles(() => ({
  loadingScreenWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    textAlign: 'center',
  },
  logo: {
    width: '26px',
    height: '28px',
  },
  textLogo: {
    width: '136px',
    height: '45px',
    paddingTop: '10px',
  },
  text: {
    fontSize: '16px',
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontWeight: 400,
    color: COLORS.black,
    marginTop: '10px',
    marginBottom: '10px',
    maxWidth: '400px',
  },
  description: {
    padding: '20px 40px',
    textAlign: 'center',
  },
  welcomeLink: {
    textDecoration: 'none',
    backgroundColor: COLORS.lightTeal,
    borderColor: COLORS.lightTeal,
    border: '1px solid #000000 !important',
    height: '40px',
    width: '200px',
    marginBottom: '20px',
    color: '#000000',
    fontSize: '13px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
