import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS } from '../../../constants'

export const styles = makeStyles(() => ({
  container: {
    padding: '20px',
  },
  title: {
    fontSize: '16px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
    marginTop: '0',
    marginBottom: '20px',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F6F6F6',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '27px',
    marginBottom: '20px',
  },
  searchButton: {},
  searchIcon: {
    width: '21px',
    height: '16px',
  },
  searchInput: {
    backgroundColor: '#F6F6F6',
    border: 'none',
    padding: '10px 20px 10px 10px',
  },
  mainGrid: {
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      width: '100%',
      margin: 0,
      '& > .MuiGrid-item': {
        padding: 0,
      },
    },
  },
  link: {
    textDecoration: 'none',
    color: '#000000DE',
  },
  noData: {
    textAlign: 'center',
    color: COLORS.lightGrey,
  },
  gridItem: {
    position: 'relative',
    cursor: 'pointer',
    marginBottom: 40,
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      marginBottom: 20,
      marginLeft: '2.5px',
      marginRight: '2.5px',
    },
  },
  errorClass: {
    objectFit: 'scale-down',
    width: '50%',
    border: '1px solid #FFF',
    height: 81,
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  image: {
    width: '50%',
    objectFit: 'cover',
    borderRadius: '3px',
    height: 81,
  },
  errorImage: {
    width: '50%',
    objectFit: 'none',
    height: 81,
  },
  description: {
    color: COLORS.lightGrey,
    marginBottom: 5,
    marginTop: 20,
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  edgeSpaces: {
    fontSize: '12px',
    fontWeight: 600,
    fontFamily: FONTS.Graphik.GraphikMedium,
  },
  imageHalfWidth: {
    width: '100%',
    objectFit: 'cover',
    borderRadius: '3px',

    marginBottom: '2px',
    height: 120,
  },
  blurb: {
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    fontFamily: FONTS.Graphik.GraphikLight + ' !important',
    fontSize: '10px !important',
    fontWeight: 400,
  },
  leftImage: {
    marginRight: '1px',
  },
  rightImage: {
    marginLeft: '1px',
  },
  loadMore: {
    backgroundColor: COLORS.lightTeal,
  },
  error: {
    fontSize: '0.875rem',
    color: '#2E7C86',
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 0,
  },
}))
