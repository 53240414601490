import React, { useState } from 'react'
import { useEffect } from 'react'
import axios from '../../axios'
import errorImg from '../../assets/images/error-image.svg'

const IgImageView = ({ url, className }) => {
  const [loading, setLoading] = useState(true)
  const [img, setImg] = useState(null)
  const [err, setErr] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        const {
          data: { data },
        } = await axios.get(
          `/api/v1/ig?igUrl=${encodeURIComponent(url + 'media/?size=l')}`
        )
        setImg(data)
        setLoading(false)
      } catch (err) {
        setImg(errorImg)
        setErr(errorImg)
        setLoading(false)
      }
    })()
  }, [])

  return loading ? (
    <div />
  ) : (
    <img src={img} className={className} alt="largeImageView" />
  )
}

export default IgImageView
