import { makeStyles } from '@material-ui/core/styles'
import { FONTS, BREAKPOINTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  blogsContainer: {
    margin: '0 20px 20px',
  },
  trendingContainer: {
    marginLeft: '20px',
  },
  trendingTitle: {
    fontSize: '16px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
  },
  heroBlogLink: {
    maxWidth: '140px',
    minWidth: '140px',
    marginLeft: '5px',
    marginRight: '5px',
    textDecoration: 'none',
    color: 'black',
  },
  blogItemContainer: {
    padding: '5px',
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      padding: '0',
    },
  },
  blogImg: {
    width: '100%',
    maxHeight: '230px',
    borderRadius: '3px',
    objectFit: 'cover',
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  blogTitle: {
    marginTop: 0,
    marginBottom: '20px',
    fontSize: '12px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
    lineHeight: '16px',
  },
  blogLink: {
    textDecoration: 'none',
    color: 'black',
  },
  heroBlogImg: {
    maxWidth: '100%',
    borderRadius: '3px',
    maxHeight: '170px',
    objectFit: 'contain',
  },
  heroPostsWrapper: {
    display: 'flex',
    overflowX: 'scroll',
  },
}))
