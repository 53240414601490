import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
  },
  logo: {
    width: '26px',
    height: '28px',
    position: 'absolute',
    right: '20.5px',
  },
  textLogo: {
    width: '136px',
    height: '35px',
  },
  headerText: {
    marginTop: '10px',
    marginBottom: '20px',
    fontSize: '18px',
  },
}))
