import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import IgImageView from '../../IgImageView'
import BirdAvatar from '../../../assets/images/chirpyest-logo.svg'
import { styles } from './styles'
import { COLORS } from '../../../constants'

interface TheChirpyestCardProps {
  userImage?: string
  items?: any
  userName?: string
  description: string
  blurb: string
}

const TheChirpyestCard = ({
  userImage,
  items,
  userName,
  description,
  blurb,
}: TheChirpyestCardProps) => {
  const classes = styles()
  const [random] = useState(Math.random())

  return (
    <div className={classes.gridItem}>
      <div className={classes.imageWrapper}>
        {userImage ? (
          <img
            src={`https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${userImage}`}
            alt="user profile image"
            className={classes.imageHalfWidth}
          />
        ) : (
          <div
            className={classes.imageHalfWidth}
            style={{
              backgroundColor:
                random < 0.5 ? COLORS.lightTeal : COLORS.watermelonPink,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={BirdAvatar}
              style={{
                filter:
                  random < 0.5
                    ? 'invert(87%) sepia(16%) saturate(162%) hue-rotate(121deg) brightness(93%) contrast(92%)'
                    : 'invert(78%) sepia(17%) saturate(602%) hue-rotate(311deg) brightness(102%) contrast(95%)',
              }}
            />
          </div>
        )}
      </div>
      <div className={classes.imageWrapper}>
        {[0, 1].map(index => {
          return items && items[index] ? (
            items[index].type === 'instagram' ? (
              <IgImageView
                url={items[index].data.link}
                className={`${classes.image} ${
                  index ? classes.rightImage : classes.leftImage
                }`}
              />
            ) : (
              <img
                src={items[index].data.imageUrl || items[index].data.base64}
                alt="product image 3"
                className={`${classes.image} ${
                  index ? classes.rightImage : classes.leftImage
                }`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.classList.remove(classes.image)
                  currentTarget.classList.add(classes.errorImage)
                  currentTarget.src = BirdAvatar
                }}
              />
            )
          ) : (
            <div
              className={classes.image}
              style={{ backgroundColor: '#F5F6F7' }}
            />
          )
        })}
      </div>
      <Typography variant="subtitle1" className={classes.description}>
        {description}
      </Typography>
      <Typography variant="body1" className={classes.edgeSpaces}>
        {userName}
      </Typography>
      <Typography className={classes.blurb}>{blurb}</Typography>
    </div>
  )
}

export default TheChirpyestCard
