import { makeStyles } from '@material-ui/core/styles'
import { FONTS } from '../../../constants'

export const styles = makeStyles(() => ({
  sortContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  brandsListError: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingTop: 40,
  },
  disclosure: {
    textAlign: 'center',
    color: '#ABABAB',
    fontFamily: FONTS.Graphik.GraphikLight,
  },
  brandChar: {
    fontSize: '16px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 600,
    textTransform: 'lowercase',
  },
  brandName: {
    fontSize: '12px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 600,
    textTransform: 'lowercase',
    margin: 0,
    textDecoration: 'none',
    color: 'black',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  featuredBrand: {
    textDecoration: 'underline',
  },
  brandContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  arrow: {
    width: '20px',
    height: '20px',
  },
  cashBackText: {
    fontWeight: 500,
    color: '#175972',
  },
  searchContainer: {
    display: 'flex',
    margin: '10px 20px',
    alignItems: 'center',
    backgroundColor: '#F6F6F6',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '27px',
  },
  searchIcon: {
    width: '21px',
    height: '16px',
  },
  searchInput: {
    backgroundColor: '#F6F6F6 !important',
    border: 'none',
    padding: '10px 0px 10px 10px',
  },
  topBrandsContainer: {
    marginLeft: '20px',
    marginRight: '20px',
  },
  topBrandsTitle: {
    fontSize: '16px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
    marginTop: '20px',
    marginBottom: '5px',
  },
  topBrandsItems: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  topBrandItem: {
    padding: '10px',
    textAlign: 'center',
    textDecoration: 'none',
  },
  topBrandImage: {
    maxWidth: '90px',
    height: '60px',
  },
  topBrandCashBack: {
    fontSize: '12px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
    marginTop: '5px',
    marginBottom: 0,
    color: '#175972',
  },
}))
