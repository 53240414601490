import { makeStyles } from '@material-ui/core/styles'
import { FONTS } from '../../constants'

export const styles = makeStyles(theme => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  boardBtn: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    display: 'flex',
    height: 54,
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
    cursor: 'pointer',
    borderColor: theme.palette.common.black,
  },
  socialText: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    display: 'block',
    margin: '0px auto',
    fontSize: '0.75rem',
    color: theme.palette.common.black,
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '2rem',
    width: '90%',
    maxWidth: 500,
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
  },
  imgWrapper: {
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 10,
  },
  error: {
    fontSize: '0.875rem',
    color: '#2E7C86',
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 0,
  },
  // customize checkbox
  checkBox: {
    display: 'block',
    position: 'relative',
    padding: '20px 25px 20px 40px',
    marginBottom: 12,
    cursor: 'pointer',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
    // hide the browser's default checkbox
    '& input': {
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
      height: 0,
      width: 0,
    },
    // style custom checkbox when checked
    '& input:checked ~ span': {
      backgroundColor: 'black',
    },
  },
  // custom checkbox mark style
  checkMark: {
    position: 'absolute',
    top: 24,
    left: 18,
    height: 15,
    width: 15,
    backgroundColor: 'white',
    border: '1px solid black',
  },
}))
